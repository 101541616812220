import React from 'react'; 
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container'; 
import { ThemeProvider, CssBaseline } from "@mui/material";
import { blinkThemeDark } from "../theme/blink";
import Header from '../components/header';  
import FooterSection from '../components/FooterSection/FooterSection';   
import { Outlet } from "react-router-dom";
function App() {
  const theme = blinkThemeDark; 



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, paddingTop: "10px"  }}> 
        <Container maxWidth="lg">
          <Header /> 
          <Outlet />
          <FooterSection/>
        </Container> 
      </Box>
  
    </ThemeProvider>
  );
}

export default App;
