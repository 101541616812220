import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActions, Button } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined"; 
import { Grid } from "@mui/material"; 
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export default function InfoCard({inputRef, onSubscribe, submissionInProgress}) {
  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: 2, paddingLeft: 0, width: "100%" }}
      className="info_section"
    >
        <Grid item xs={12} md={6} className="">
        <Card className="info_card subscribe_info ">
          <NotificationsActiveIcon className="info_icon " />
          <CardContent className="subscribe_content">
          <Typography color="white" component={"p"}>
            {"Sign up for public beta."}
          </Typography>
          <Typography color="white" component={"p"}>
            {"Get early access to iMarkets as  soon as the beta is released."} <br/> <br/> &nbsp;
          </Typography>
          </CardContent>
          <CardActions>
          <Grid className="subscribe_searchbox">
            <MailOutlineOutlinedIcon />
            <input ref={inputRef} type="text" placeholder="youremail@email.com" />
            <Button disabled={submissionInProgress} variant="contained" onClick={() => onSubscribe(inputRef.current.value)}>{"Submit"}</Button>
          </Grid> 
          </CardActions>
        </Card>
      </Grid>  
      <Grid item xs={12} md={6}>
        <Card className="info_card">
          <InsertChartOutlinedOutlinedIcon className="info_icon" />
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              The Brokerage
            </Typography>
            <Typography variant="body2" color="text.secondary">
              A zero commission, stock and options brokerage personalized to
              you. Get Access to advanced trading tools, multiple strategies,
              advanced scanner and more.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" className="primary-button">
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
