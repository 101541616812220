import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import banner from "../assets/images/mainbanner.png";
import Grid from '@mui/material/Grid';

const HeroSection = () => {
    return (
        <Grid container spacing={0} alignItems="center"
            justifyContent="center">
            <Grid item md={6} xs={12} >
                <Box
                    id="cover-hero-text"
                    className="hero-text"
                >
                    <Box className="">
                        <Typography component={"p"}sx={{color:"#1D5CFF"}}>
                            {"Learn,"} <br /> {"Grow, Trade."}
                        </Typography>
                        <Box>
                            <Typography
                                component={"p"}
                                color="white"
                                className="hero-text--small"
                            >
                                {
                                    "The future of education in the investment and trading space"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={6} xs={12} style={{ overflow: "hidden", padding: "20px", textAlign: "center" }}>
              
                <img src={banner} width="80%" alt={' Imarkets '}  /> 
            </Grid>
        </Grid>)
};

export default HeroSection;
